'use strict'

import loadScript from "../libs/@elements/load-script";

export function initInScope($scope) {

    if (_config.raffle !== true) {
        return;
    }

    if (_config.reCaptchaV3 !== true) {
        return;
    }

    const apiKey = _config.reCaptchaKey;

    if (!apiKey) {
        return $.Deferred().reject(new Error('reCaptcha key is not set. Please set _config.reCaptchaKey'));
    }

    loadScript('https://www.google.com/recaptcha/api.js?render=' + apiKey).done(function () {
        grecaptcha.ready(function () {

            const $responseInputs = $scope.find('.recaptcha-response');

            $responseInputs.each(function() {

                const $recaptchaResponse = $(this);
                const $recaptchaAction = $recaptchaResponse.siblings('.recaptcha-action');
                const $form = $recaptchaResponse.closest('form');
                const $submitBtn = $form.find(':submit');

                let tempElement = $("<input type='hidden'/>");

                // clone the important parts of the button used to submit the form.
                tempElement
                    .attr("name", $submitBtn.attr('name'))
                    .val($submitBtn.val())
                    .appendTo($form);


                let tokenPromise = null;

                $form.on('submit', function(ev) {

                    ev.preventDefault();

                    $submitBtn.attr('disabled', true);

                    if(!tokenPromise) {
                        tokenPromise = grecaptcha.execute(apiKey, {action: $recaptchaAction.val()})
                            .then( function(token) {
                                $recaptchaResponse.val(token);
                                $form.unbind('submit').submit();
                            })
                            .catch(function(e){
                                console.log('error', e)
                            })
                            .then(function() {
                                $submitBtn.removeAttr('disabled');
                                tokenPromise = null;
                            });
                    }
                });
            });
        });
    }).catch(e => {
        console.log(e);
    });

}

export function submitAjaxForm(url, formData, callback) {

    if (_config.raffle !== true) {
        return;
    }

    let tokenPromise;

    if (_config.reCaptchaV3 !== undefined) {
        const apiKey = _config.reCaptchaKey;

        if (!apiKey) {
            return $.Deferred().reject(new Error('reCaptcha key is not set. Please set _config.reCaptchaKey'));
        }

        loadScript('https://www.google.com/recaptcha/api.js?render=' + apiKey).done(function () {

            grecaptcha.ready(function () {
                if (tokenPromise) {
                    tokenPromise.abort();
                    tokenPromise = null;
                }

                tokenPromise = grecaptcha.execute(apiKey, {action: 'product_rating'})
                    .then(function (token) {
                        // Add captcha token to form data
                        formData.append('g-recaptcha-response', token);
                        callback(url, formData);
                    })
                    .catch(function (e) {
                        console.log('error', e)
                    })
                    .then(function () {
                        tokenPromise = null;
                    });
            });
        });
    } else {
        callback(url, formData);
    }
}