"use strict";

import {onFind} from "../libs/@elements/init-modules-in-scope";
import {on, hasClass} from '../libs/@elements/dom-utils';

class TrackingHandler {
    supports() {
    }

    handle(payload, callback) {
    }
}

class TagManagerHandler extends TrackingHandler {
    supports(type) {
        return dataLayer != null && type === "gtm";
    }

    handle(payload) {
        dataLayer.push(payload);
    }
}

const handlers = [
    new TagManagerHandler()
];

export function init() {
    onFind('.js-tracking', function (trackingElement) {
        track(trackingElement, [new DelegationConstraint('.js-compare-list__tracking, .js-wishlist__tracking')]);
    });

    onFind('.js-compare-list__tracking', function (trackingElement) {
        track(trackingElement, [new HasClassConstraint('is-active')]);
    });

    onFind('.js-wishlist__tracking', function (trackingElement) {
        track(trackingElement, [new HasClassConstraint('is-active')]);
    });
}

function track(trackingElement, constraints) {
    let action = trackingElement.getAttribute("data-tracking-action");
    let type = trackingElement.getAttribute("data-tracking-type");
    let payload = trackingElement.getAttribute("data-tracking-payload") ? JSON.parse(trackingElement.getAttribute("data-tracking-payload")) : null;

    if (action) {
        on(action, function (evt) {
            if (!evaluateConstraints(constraints, trackingElement, evt)) {
                return;
            }
            handle(payload, type);
        }, trackingElement);
    } else {
        if (!evaluateConstraints(constraints, trackingElement)) {
            return;
        }
        handle(payload, type);
    }
}

function evaluateConstraints(constraints, trackingElement, event) {
    for (let constraint of constraints) {
        if (! constraint instanceof TrackingConstraint) {
            continue;
        }
        if (!constraint.isAllowed(trackingElement, event)) {
            return false;
        }
    }

    return true;
}

function handle(payload, type) {
    handlers.map((handler) => {
        if (!handler.supports(type)) {
            return;
        }

        handler.handle(payload);
    });
}

/*
 * Push tracking calls from JSON responses into dataLayer
 *
 * The __tagManagerPayload variable is populated for JSON responses inside the TagManagerSubscriber
 * @see \App\EventSubscriber\Tracking\TagManagerSubscriber::onResponse
 */
export function handleTrackingOnPromise(promise) {
    promise.then(function (response) {
        return response.clone().json();
    }).then(data => {
        if (data.__tagManagerPayload) {
            if (data.__tagManagerPayload.length) {
                data.__tagManagerPayload.map((payload) => {
                    dataLayer.push(payload);
                });
            } else {
                dataLayer.push(data.__tagManagerPayload);
            }
        }
    });
}




class TrackingConstraint {
    isAllowed(element = null, event = null) {
        return true;
    }
}

class HasClassConstraint extends TrackingConstraint {
    constructor(selector) {
        super();
        this.selector = selector;
    }
    isAllowed(element, event = null) {
        return hasClass(this.selector, element);
    }
}

class DelegationConstraint extends TrackingConstraint {
    constructor(selector) {
        super();
        this.selector = selector;
    }
    isAllowed(element = null, event) {
        if (typeof event === typeof undefined) {
            return true;
        }
        if (typeof event.target === typeof undefined) {
            return true;
        }
        const $target = event.target;
        if (hasClass(this.selector, $target)) {
            return false;
        }

        return true;
    }
}