"use strict";

import {getPrefixedDataSet} from "../libs/@elements/data-set-utils";
import throwError from "../libs/@elements/throw-error";
import {findIn, findAllIn} from '../libs/@elements/dom-utils';
import {onFind} from "../libs/@elements/init-modules-in-scope";
import {scrollTo, getScrollWidth} from "./scrolling-area";
import {translate} from "../../shared/translations/translations";

export function init () {
    const defaultOptions = {
        range: {
            min: 0,
            max: 100
        },
        start: [0, 100],
        connect: [true, false, false],
        handleAttributes: [
            { 'aria-label': translate('aria.slider.lower-value')},
            { 'aria-label': translate('aria.slider.upper-value')},
        ]
    };

    let currentStep = 0;

    import('nouislider').then(function (noUiSlider) {
        onFind('.js-history-slider', function (container) {
            let slider = findIn('.js-history-range', container);
            let scrollArea = findIn('.js-history-slider__area', container);

            let options = {
                ...defaultOptions,
                ...transformDataSetOptions(getPrefixedDataSet('range-slider', slider))

            };

            let sliderElement = findIn('.js-history-range__slider', slider),
                input = findIn('.js-history-range__input', slider),
                decimalPlaces = 0;

            if (options.step < 1) {
                decimalPlaces = options.step.toString().length - 2;
            }

            noUiSlider.create(sliderElement, options);

            sliderElement.noUiSlider.on('update', (values, handle) => {
                let value = +values[handle];

                input.value = value.toFixed(decimalPlaces);

                call(options.onUpdate, {
                    element: slider,
                    value,
                    decimalPlaces,
                    handle
                });
            });

            // sliderElement.noUiSlider.on('set', (values, handle) => {
            //     let value = +values[handle];
            //
            //     let items = findAllIn('.js-scrolling-area__item', container);
            //     const scrollWidth = getScrollWidth(items[value]);
            //     let count = value - currentStep;
            //
            //     if (value + 1 === options.range.max && value < currentStep) {
            //         scrollTo(scrollArea, scrollArea.scrollLeft - scrollWidth / 2);
            //     } else {
            //         scrollTo(scrollArea, scrollArea.scrollLeft + scrollWidth * count);
            //     }
            //
            //     currentStep = value;
            //
            //     call(options.onSet, {
            //         element: slider,
            //         values,
            //         decimalPlaces,
            //         handle
            //     });
            // });


            let isScrolling;
            scrollArea.addEventListener('scroll', function (evt) {
                window.clearTimeout(isScrolling);

                isScrolling = setTimeout(function() {
                    let id = findIn('.js-scrolling-area__item.is-active', container).dataset.scrollingAreaId;
                    currentStep = id;
                    sliderElement.noUiSlider.set(currentStep);
                }, 66);
            }, false);


        });
    });
}

function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.range) {
        try {
            transformedOptions.range = JSON.parse(transformedOptions.range)
        } catch (e) {
            transformedOptions.range = null;
            throwError(RANGE_ERROR_MESSAGE);
        }
    }

    if (transformedOptions.start) {
        try {
            transformedOptions.start = JSON.parse(transformedOptions.start)
        } catch (e) {
            transformedOptions.start = null;
            throwError(START_ERROR_MESSAGE);
        }
    }

    if (transformedOptions.step) {
        transformedOptions.step = parseFloat(transformedOptions.step);
    }

    if (transformedOptions.margin) {
        transformedOptions.margin = parseFloat(transformedOptions.margin);
    }

    return transformedOptions;
}

const RANGE_ERROR_MESSAGE = `Range-Slider error: data-range-slider-range has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.`;
const START_ERROR_MESSAGE = `Range-Slider error: data-range-slider-start has to be a a valid JSON object. Most likely you used single quotes instead of double quotes for the JSON fields.`;

function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}
